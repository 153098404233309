.textarea-container {
    position: relative;
    width: 100%;
}

.custom-textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    resize: none; /* Prevent manual resize since we're auto-expanding */
    outline: none;
    border: 2px solid #ccc;
    border-radius: 5px;
    overflow: hidden; /* Hide scrollbar */
    min-height: 40px; /* Initial height */
}

.charCounter {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 12px;
}
.custom-textarea:hover {
    border-color: #999; /* Slightly darker border on hover for subtle feedback */
}

.custom-textarea:active,
.custom-textarea:focus { /* Including :focus for when the textarea is selected */
    border-color: #666; /* Even darker border when active or focused */
    box-shadow: 0 0 5px rgba(0,0,0,0.2); /* Optional: Add a subtle shadow for depth */
}
/* Responsive adjustments */
@media (max-width: 480px) {
    .custom-textarea {
        font-size: 14px;
        padding: 8px;
    }
    .charCounter {
        font-size: 10px;
    }
}
