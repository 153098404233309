.dark .apexcharts-text {
    fill: whitesmoke ;/* Change this to the desired color */
  }
.apexcharts-legend-label-text {
  }

  /* Target the Sankey chart node labels (example selector, adjust as needed) */
.gantt-chart-text {
  fill: red; /* Set the label text color to red */
}

.sankey-chart-label {
  fill: rgb(85, 17, 211) !important; /* Use !important to ensure the style is applied */
}