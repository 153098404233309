.cronus-logo{
    width: 65px;
    height: 65px;
    border-radius: 50%;
	justify-content: start;
}

.light .cronus-logo {
	background-color: #273c63;
}

@media (max-width: 768px) {
	.cronus-logo{
		display: none;
	}
}
.cronus-logo-multi-factor-authentication {
	width: 300px; 
	height: 300px;
	margin-bottom: 1.5rem;
}
.cronus-logo-landing-page {
	width: 200px;
	height: 200px;
	margin-bottom: 1.5rem;
}

.nav {
	list-style-type: none;
	padding: 0 .81rem;
	background: #273c63;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 1000;
}

.dark .nav  {
    background: #1e273b;   
	color: #cbd5e1;
}

.nav-content {
	display: inline-block;
	position: relative; 
	padding: .5rem .5rem;
	font-weight: 700;
	font-size: 1.125rem; 
	line-height: 1.75rem; 
	text-decoration: none;
	color: #f5f5f5;
	margin: 0 0.25rem;
}

.dark .nav-content {
	color: #cbd5e1;
}

.client-bp-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.nav-dropdown-content.active , .nav-content.active, .nav-dropdown-secondary-content.active {
	color: #ce9f2c;
}
.nav-content:hover, .nav-dropdown-content:hover , .nav-dropdown-secondary-content:hover {
	color: #ce9f2c;
}

.dark .nav-content:hover,  .dark .nav-dropdown-content:hover , .dark .nav-dropdown-secondary-content:hover {
	color: #ce9f2c;
}

.nav-dropdown-container, .nav-secondary-dropdown-container {
    display: none;
    position: absolute;
    background-color: #f3f4f6;
    min-width: max-content;
    border-radius: 1rem;
    box-shadow: 0px 8px 16px 0px hwb(0 0% 100% / 0.2);
	padding: .5rem .25rem;	
	z-index: 1000;
}
.config-business-process {
	padding-bottom: 4px;
}

.dark .nav-dropdown-container, .dark .nav-secondary-dropdown-container {
	color: #f5f5f5;
	background-color: #374151;
 }

.nav-dropdown-container {
   display: block;
   right: 0;
	
}
.nav-secondary-dropdown-container {
	display: block;
	left: 100%;
	top: 0
}
.client-bp-dropdown-header {
	font-weight: 600;
	line-height: 1.5rem; 
	padding: .1rem 2rem 0 .25rem;
	color: #030712; 
	text-decoration: underline;
}
.bp-dropdown-box {
	margin: .25rem .5rem .25rem 2rem;
} 

.nav-dropdown-content , .nav-dropdown-secondary-content {
	font-weight: 600;
	font-size: 1rem; 
	line-height: 1.5rem; 
	padding: 0 2rem .25rem .5rem;
	
	color: #030712; 
}
.dark .nav-dropdown-content  ,.dark .nav-dropdown-secondary-content  {
	color: #f5f5f5;
}

.dropdown-icon {
	display: inline-block;
	position: absolute;

}
.user-name {
	padding: .2rem;
	border-radius: 90%;
	border-color: #ce9f2c;
	border-width:  0px 1px;
}


