@import './colors.css';
@import "tailwindcss/base";
@import "tailwindcss/utilities";
@import "tailwindcss/components";

/* @import "bootstrap/dist/css/bootstrap.min.css"; */


/* @import "./custom-base-styles.css"; */
@import "assets/css/components/pagination.css";
@import "assets/css/components/loading.css";
@import 'assets/css/components/Table.css';
@import 'assets/css/components/btn.css';
@import "assets/css/components/navbar.css";
@import 'assets/css/components/form.css';    
@import 'assets/css/components/select.css';    
@import 'assets/css/components/CyberMindDropDown.css';    
@import 'assets/css/components/chart.css';

@import "tailwindcss/utilities";
/* @import 'assets/css/components/controlMain.css'; */



@import 'assets/css/components/Modal.css';
@import 'assets/css/components/fileUpload.css';

/* @import 'assets/css/components/ModalVendors.css'; */
/* @import 'assets/css/components/rangeSlide.css'; */
/* @import 'assets/css/components/styleFromAdminHeader.css'; */
/* @import 'assets/css/components/styleFromRiskAnalysis.css'; */
/* @import 'assets/css/components/styleFromThreatScops.css'; */
/* @import 'assets/css/components/stylesFromControlAnalysis.css'; */
/* @import 'assets/css/components/Tablez.css'; */


@import 'assets/css/utilities/styles.css'; 
@import 'assets/css/utilities/style.css';
@import 'assets/css/utilities/stylesFromClients.css';
@import 'assets/css/utilities/Test.css';

/* html.light {
  font-family: Verdana;
  margin: 0;
  height: 100%;
  background-color:  rgba(193, 188, 188, 0.289);
  color: black;
}
*/
html.dark {
  font-family: Verdana;
  margin: 0;
  height: 100%;
  background-color:  rgb(15 23 42);
  color: #979ea5;
} 
.swal-text {
  color: black; /* Replace with your desired text color */
  font-size: medium;
  text-align: start;
}
.swal-button--confirm {
  background: #0a0;
}
.swal-button {
  background-color: #ce9f2c;
  color: #ffffff;
}
.text-color {
  color: #ce9f2c;
}
.swal-button button:hover {
  background-color: #ce9f2c !important;
  color: #ffffff;
}
.swal-button:hover {
  background-color: #ce9f2c !important;
  color: #ffffff;
}

.sweet-alert button:hover {
  background-color: #ce9f2c;
}

textarea {
  overflow-y: scroll;
  height: 100px;
  resize: none;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #273c63 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.modal-buton{
 background:  #F7DD9E;
 color: #273c63;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-confirm {
  background-color: #ce9f2c;
}
.button {
  background-color: #ce9f2c !important;
  color: white !important;
}

.cmbutton {
  background-color: #273c63 !important;
  color: #ce9f2c !important;
  border-color: #ce9f2c !important;
}
.cmbuttons {
  background-color: #ce9f2c !important;
  color: white !important;
  border-color: #ce9f2c !important;
}
.cmbutton:hover {
  background-color: #ce9f2c !important;
  color: white !important;
  border-color: white !important;
}

.cmbutton:active {
  background-color: #ce9f2c !important;
  color: white !important;
  border-color: white !important;
}

.cmbutton:focus {
  background-color: #ce9f2c !important;
  color: white !important;
  border-color: white !important;
}

.altbutton {
  background-color: #273c63 !important;
  color: white !important;
  border-color: white !important;
}
.altbutton:hover {
  background-color: #ce9f2c !important;
  color: white !important;
  border-color: white !important;
}

.total-effectiveness-card {
  font-size: 0.88em;
  letter-spacing: 0.1em;
  background-color: #273c63;
  color: #fff;
}

.low-total-effectiveness-card {
  font-size: 0.88em;
  letter-spacing: 0.1em;
  background-color: #273c63;
  color: #fff;
}

.low-total-effectiveness-card:hover {
  cursor: default;
}

.total-effectiveness-card:hover {
  opacity: 0.95;
}


thead button.ascending::before {
  font-size: large;
  content: "↑";
  margin-right: 5px;
  font-weight: bolder;
}

thead button.descending::before {
  font-size: large;
  content: "↓";
  margin-right: 5px;
  font-weight: bolder;
}

textarea {
  overflow: auto;
}
.dropdown-item {
  color: #ffffff !important;
}
.dropdown-item:hover {
  color: #000 !important;
}
.dropdown-item:active {
  background-color: #273c63;
}

.threat-profiling-card-font {
  font-size: 4rem;
}




.scrollbar-hide {
  height: 100vh;
  overflow: auto;
}

.google-Sankey-node:hover rect {
  stroke-width: 3;
  stroke: #000;
}
svg path:hover {
  fill: #fac131;
}

/* File Upload Feedback Loading animation*/
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fac131;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.pageNumbersWrapper ul {
  display: none;
}
.DropDownIcon{
text-decoration: none;
	color: white;
	font-weight: bold;
	a {
		color: #fff;
	}
	a:hover {
		color: yellow;
	}
}
/* new  */

.surface {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header-container {
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
}

.header-nav {
  transition: background-color 300ms;
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--dynamic-text-transparent);
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  max-height: 48px;
  padding: 8px;
}
.dropdown_sign{
    display: inline-block;
    line-height: 1;
}


