.paginationBttns {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  
  .light .paginationBttns {
    background-color: rgb(214 211 209);
  }
  .dark .paginationBttns {
    background-color: rgba(55, 65, 81);
  }
    
  .paginationBttns ul {
    list-style: none;
  }
  .paginationBttns li {
    padding-top: 18px;
    margin: 0px;
    justify-content: center;
    justify-items: center;
  }

  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    justify-content: center;
    justify-items: center;
    border-radius: 8px;
    border: 1px solid #ce9f2c;
    color: #ce9f2c;
    cursor: pointer;
  }
  .dark .paginationBttns a {
    color: rgb(156 163 175);
    border: 1px solid rgb(75 85 99);
  }
  .paginationBttns a:hover {
    color: white !important;
    background-color: #ce9f2c;
  }
  
  .paginationActive a {    
    background-color: #273c63;
    color: white !important;
    opacity: 0.9;
    cursor: default;
  }
  
  .paginationActive a:hover {
    color: white !important;
    background-color: #273c63;
    opacity: 0.9;
  }
  
  .paginationDisabled a {
    color: white;
    background-color: grey;
    opacity: 0.5;
  }
  
  .paginationDisabled a:hover {
    color: white;
    background-color: grey;
    opacity: 0.5;
    cursor: default;
  }
  