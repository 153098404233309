.main-container {
  padding: 2.5rem/* 40px */;
  display: flex;
  outline: none;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  position: fixed;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
.tables {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  min-width: fit-content;
  font-size: 1rem;
  border-bottom-width: 1px;
  border-top-width: 1px;
}
.tables caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
tbody {
  border-top-width: 1px;
  border-color: #838181;
}
.tables tr {
  background-color: #f5f5f4;

}
.tables tr:nth-child(even) {
  background-color:  #e7e5e4;
}
.dark .tables tr {
  background-color: #1e293b;
}
.dark .tables tr:nth-child(even) {
  background-color:#1f2937;
}

.tables td {
  color: rgb(41 37 36);
  padding: 0.25em;
  text-align: left;
}
.dark .tables td {
  color: #f1f5f9;
}

.tables th {
  background:#808083;
  color: #ffffff;
  padding: 0.25em;
  text-align: left;
  font-size: 0.99em;
  cursor: pointer;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .tables th {
    font-size: 0.8em; 
  }
}

.dark .tables th {
 background: #1e273b;
 color: #cbd5e1;
}

.table-title {   
  display: flex;
  background: #273c63;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em;
  font-weight: 700;
  border-top-left-radius: 1rem/* 16px */;
  border-top-right-radius: 1rem;
  padding: 1rem; 
}
.dark .table-title {
  background-color: #1e273b;
  color: #cbd5e1;
}

.bg {
  background-color: #04365d;
  color: white;
}

.alert-no-data {
  color: #664d03;
  background-color: #fff3cd;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem;
  width: 100%;
  border: 1px solid #ffecb5;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.search-value {
  text-decoration: underline;
  font-style: bold;
  padding: 0 0.264rem;
}
.attachment-container {
  /* width: 140px; */
  padding: 1rem;
  border-radius: 1rem;
}
.drop-file-icon {
  padding: 0.5rem;
  border: 1px solid  #ce9f2c ;
  border-radius: 1rem;
  background-color: #1a572d ;
}

.drop-file-name-container {
  display: flex;
  width: 140px;
  font-weight: 600;
	font-size: 1rem; 
	line-height: 1.5rem; 
	padding: 0 1rem .5rem .5rem;
}

.drop-file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.drop-file-dd-icon {
  display: flex;
  justify-content: end;
}

.file-upload-dropdown-container {
  display: none;
  position: relative;
  /* background-color: #f3f4f6; */
  min-width: max-content;
  border-radius: 1rem;
  box-shadow: 0px 8px 16px 0px hwb(0 0% 100% / 0.2);
	padding: .5rem .25rem;	
	z-index: 1000;
}

.file-upload-dropdown-container{
  display: block;
	left: 100%;
  top: 100%;
 }

.file-upload-dropdown-item {
	font-weight: 600;
	font-size: 1rem; 
	line-height: 1.5rem; 
	padding: 0 2rem .25rem .5rem;
	color: #030712; 
}
.file-previewer {
  max-height: 400px;
  overflow-y: auto;
  max-width: 100%;
  overflow-x: auto;
  margin: 0;
  padding-left: 0;
}