* {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  max-width: 1000px;
  margin: 1em auto;
  padding: 1em;
}

.is-striped {
  background-color: rgba(233, 200, 147, 0.2);
}

/* Table column sizing
================================== */
.date-cell {
  width: 20%;
}

.topic-cell {
  width: 41%;
}

.access-link-cell {
  width: 13%;
}

.replay-link-cell {
  width: 13%;
}

.pdf-cell {
  width: 13%;
}
.table_width {
  width: 1500px;
}

/* Apply styles
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}
.Rtable .Rtable-row {
  width: 100%;
  display: flex;
}
.Rtable .Rtable-row .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
}
.Rtable .Rtable-row .Rtable-cell.column-heading {
  background-color: #43bac0;
  color: white;
  padding: 1em;
}
.Rtable .Rtable-row .Rtable-cell .Rtable-cell--heading {
  display: none;
}
.Rtable .Rtable-row .Rtable-cell .Rtable-cell--content a {
  font-size: 2em;
  color: #333;
}
.Rtable .Rtable-row .Rtable-cell .Rtable-cell--content .webinar-date {
  font-weight: 700;
}

/* Responsive
==================================== */
@media all and (max-width: 750px) {
  .is-striped {
    background-color: white;
  }

  .Rtable--collapse {
    display: block;
    width: 100%;
    padding: 1em;
    box-shadow: none;
  }
  .Rtable--collapse .Rtable-row {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  }
  .Rtable--collapse .Rtable-row .Rtable-cell {
    width: 100% !important;
    display: flex;
    align-items: center;
  }
  .Rtable--collapse .Rtable-row .Rtable-cell .Rtable-cell--heading {
    display: inline-block;
    flex: 1;
    max-width: 120px;
    min-width: 120px;
    color: #43bac0;
    font-weight: 700;
    border-right: 1px solid #ccc;
    margin-right: 1em;
  }
  .Rtable--collapse .Rtable-row .Rtable-cell .Rtable-cell--content {
    flex: 2;
    padding-left: 1em;
  }
  .Rtable--collapse .topic-cell {
    background-color: #43bac0;
    color: white;
    font-weight: 700;
    order: -1;
  }
  .Rtable--collapse .topic-cell .Rtable-cell--content {
    padding-left: 0 !important;
  }
  .Rtable--collapse .Rtable-row--head {
    display: none;
  }
}
.no-flexbox .Rtable {
  display: block;
}
.no-flexbox .Rtable.Rtable-cell {
  width: 100%;
}
.highest{
 background: "#4ABA00"
}