.h-card {
  height: 100%;
}
.h {
  height: 420px;
}
.h-row-one {
  height: 350px;
}
.map_width {
  width: 1000px !important;
  height: 650px !important;
}
.hieght_map {
  height: 1800px !important;
  width:800px !important;
  
}
.w-cell {
  width: 1px !important;
}
.assets{
  background:#6858F2;
}
.vendors{
  background:#101f3a;
}
/* .main{
  background: #273c63;
 } */




.highest{
  background: #CECE5A;
 }
.medium{
  background: #FFE17B
 }
.low{
  background: #FD8D14
 }
.assets{
  background: #F1F0E8
 }
.users{
  background: #EEE0C9
}
.vendor{
  background: #ADC4CE
}
.dark .assets .vendor .users{
  background: #D8D9DA;
}