:root {

    --color-cronus-primary: #273c63; 
    --color-cronus-secondary: #ce9f2c;
    
    --color-nav-text: #f5f5f5;
    --color-nav-text-dark: #cbd5e1;

    /* COLOR background  */
    --color-high: #d32f2fc4;
    --color-medium: #fafa5b;
    --color-low: #0080009d;


    --color-background_dark: #2d2d2d;
    --color-text-dark: #1f2937;

    /*  color alert*/

    /* chart colors */
    /* colors: ['#04365d', '#F7DD9E', '#70798A', '#872646', '#ce9f2c'], */
    
    --color-chart-one: #375e87;
    --color-chart-two: #ce9f2c;
    --color-chart-three: #872646;
    --color-chart-four: #e87c10;
    --color-chart-five: #70798A;
    --color-chart-six: #00BFFF;

       /* vulnerabilities bg color */
    --color-vulnerable-critical: #862E40;
    --color-vulnerable-high: #CC5555;
    --color-vulnerable-medium: #E29252;
    --color-vulnerable-low: #F1CA65;
    --color-vulnerable-info: #66ACE0;



    /* threat-severity */
    --color-severity-very-low: #90EE90;  
    --color-severity-low: #008000;      
    --color-severity-moderate: #FFFF00; 
    --color-severity-high: #FFA500;     
    --color-severity-critical: #800000;  
   


    /* table */
    --color-tables-tr-background: #f5f5f4; 
    --color-tables-tr-background-dark: #1e293b; 

    --color-tables-tr-even-background: #e7e5e4;   
    --color-tables-tr-even-background-dark: #1f2937; 
 

    


  }