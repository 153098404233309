
.modal {
  position: fixed;
  top: 45px;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  margin-bottom: 100px;
}
.modal.show {
  display: block;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 1rem;
  pointer-events: none;
  display: flex; 
  justify-content: center;
  align-items: center; 
  .modal.fade & {
  transform: .3s ease-out,-webkit-transform .3s ease-out;
  }
  .modal.show & {
    transform: none;
  }
}

.modal-dialog-scrollable {
  height: subtract(100%, 1rem * 2);
  .modal-content {
    max-height: 100%;
    overflow: hidden;

  }
  .modal-body {
    overflow-y: auto;
  }
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: subtract(100%, 1rem * 2);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; 
  pointer-events: auto;
  border:  1px solid  #0c0a09;
  border-radius: 1rem; 
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background-color: #f5f5f4;
  background-clip: padding-box;
  outline: 0;
  margin: auto; 
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: .5;
    filter: blur(4px);
  }
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom: 1px solid #bbb9b8;
  padding: 1rem;
  position: sticky;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; 
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top: 1px solid #0c0a09;
  border-bottom: 1px solid  #0c0a09;
  padding: 1rem;
  /* margin-bottom: 1rem; */
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100%;
    margin: 1rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 2rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 2rem);
    
  }

  .modal-content {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  .modal-sm {
    max-width: 500px;
  }
}

@media (min-width: 992px) {
  .modal-lg{
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}



.modal-footer > button {
  margin-left: 2rem;
}

.dark .modal-content {
  border: 1px solid rgb(12 10 9);
  background-color: rgb(31 41 55);
}

.modal-two-columns {
  display: grid;    
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.modal-three-columns {
  display: grid;    
  grid-template-columns: repeat(3, 1fr); 
  gap: 1rem;
}



/* used until everything is updated */
/* Modal body with two columns */
.modal-body-medium {
  grid-template-columns: repeat(2, 1fr);
}
/* Modal body with one column */
.modal-body-small {
  grid-template-columns: 1fr;
}

/* Override the width for modal-body-small */
.modal-body-small + .modal {  
  width: 40%; /* Override width for modal-body-small */
}

.modal-title {
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 600;
}