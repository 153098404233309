.btn_file_upload {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #273c63 ;
    border-color: #ce9f2c ;
    border-radius: 8px;
    padding: 0.29rem /*p-1=0.25rem=4px,  p-2.5 0.625rem 10px */;
  }

 .dark .btn_file_upload {
    background-color: rgba(55, 65, 81);  
    border: 1px solid rgb(203 213 225);
  } 
  .light .btn_file_upload {
    background-color: rgba(55, 65, 81);  
    border: 1px solid #ce9f2c;
  }
  .btn_file_upload:hover {
    background-color: #ce9f2c ;
    color: white ;
    border-color: white ;
  }

  .btn_file_download {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #273c63 ;
    border-color: #ce9f2c ;
    border-radius: 8px;
    padding:0.55rem /*p-1=0.25rem=4px,  p-2.5 0.625rem 10px */;
  }

 .dark .btn_file_download {
    background-color: rgba(55, 65, 81);  
    border: 1px solid rgb(203 213 225);
  } 
  .light .btn_file_download {
    background-color: rgba(55, 65, 81);  
    border: 1px solid #ce9f2c;
  }
  .btn_file_download:hover {
    background-color: #ce9f2c ;
    color: white ;
    border-color: white ;
  }

  .btn-add-new ,  .btn-append , .btn-replace {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.625rem /*p-1=0.25rem=4px,  p-2.5 0.625rem 10px */;
    border-width: 1px; 
    border-radius: 0.625rem/* 6px */;
    border-color: #cea02cd2 ;
    background-color: #ce9f2c ;
  }

 .dark .btn-add-new ,  .dark .btn-append ,  .dark .btn-replace {
    color: rgb(203 213 225); 
    background-color: rgba(55, 65, 81);  
    border-color: rgb(203 213 225);
  }
  .btn-add-new:hover {
    background-color: #ce9f2c ;
    color: white ;
    border-color: white ;
  }
  .btn-append:hover {
    background-color: #cea02cd3 ;
    color: white ;
    border-color: white ;
  }

  .btn-replace:hover {
    background-color: #8f2e2ef0 ;
    color: white ;
    border-color: white ;
  }
/* 
cancel btn 
*/

.btn-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem /*p-1=0.25rem=4px,  p-2.5 0.625rem 10px */;
  border-width: 1px; 
  border-radius: 0.625rem/* 6px */;
  color: white ;
  background-color: rgba(55, 65, 81);  
  border-color: rgb(203 213 225);
}

.dark .btn-cancel {
  color: #cbd5e1b7; 
  background-color: #1e2a3cb0;  
  border-color: rgb(203 213 225);

}

.btn-cancel:hover {
  color: #ffffff ;
  border-color: white ;
  background-color: rgb(40, 53, 74)
}

  /* btn edit */
  .btn-edit  .btn-delete  {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    stroke: red;
    fill: red;
    padding: 0.25rem;

  }

  .btn-edit {
    margin-right: 8px;
  }



  /* btn delete */

  .btn-delete:hover {
    background-color:  #8a3131 ;
    border-radius: 50%;
  }


.clear-btn {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: flex;
}

/* Target the IconButton component and its nested Icons */
.dark .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root {
  color: rgb(196, 193, 193); /* Change this to the desired color for EditIcon */
}

.see_more{
  font-size: medium;
  color: #ce9f2c;
}